<template>
  <div class="order-summary">
    <div class="order-container">
      <h2 class="bold-text">{{ $t("order summary") }}</h2>
      <p class="light-text" :class="[localDisplayLang === 'ara' ? 'small-text-rtl' : 'small-text']" dir="ltr">
        {{ currentDate }}
      </p>
      <div class="plan-title" v-if="orderDetails.transactionPurpose === 'SUBSCRIPTION'">
        <h3 class="bold-text">{{ summaryDetails.planname }}</h3>
        <p class="light-text small-text">{{ validity }} {{ $t("days validity") }}</p>
      </div>
      <div class="plan-title" v-else-if="orderDetails.transactionPurpose === 'PURCHASE'">
        <h3 class="bold-text">{{ orderDetails.content.title }}</h3>
      </div>
      <div class="cost-details">
        <div class="rows cost light-text">
          <p>{{ $t("price") }}</p>
          <div class="price" dir="ltr">
            <span
              :class="[localDisplayLang === 'ara' ? 'currency_rtl' : 'currency']"
              v-if="summaryDetails.currency === 'USD'"
              >$</span
            >
            <span
              :class="[localDisplayLang === 'ara' ? 'currency_rtl' : 'currency']"
              v-if="summaryDetails.currency === 'INR'"
              >&#x20B9;</span
            >
            <p>{{ costPrice }}</p>
          </div>
        </div>
        <div class="rows light-text">
          <p>{{ $t("Discount") }}</p>
          <div class="price" dir="ltr">
            <span
              :class="[localDisplayLang === 'ara' ? 'currency_rtl' : 'currency']"
              v-if="summaryDetails.currency === 'USD'"
              >$</span
            >
            <span
              :class="[localDisplayLang === 'ara' ? 'currency_rtl' : 'currency']"
              v-if="summaryDetails.currency === 'INR'"
              >&#x20B9;</span
            >
						<p v-if="summaryDetails.planinterval === `YEAR`">{{ discountPrice ? discountPrice : 0  }}</p>
            <p v-else >{{ discountPrice ? discountPrice : 0 }}</p>
          </div>
        </div>
        <div class="rows total-cost bold-text">
          <h4>{{ $t("total price") }}</h4>
          <div class="price" dir="ltr">
            <span
              :class="[localDisplayLang === 'ara' ? 'currency_rtl' : 'currency']"
              v-if="summaryDetails.currency === 'USD'"
              >$</span
            >
            <span
              :class="[localDisplayLang === 'ara' ? 'currenct_rtl' : 'currency']"
              v-if="summaryDetails.currency === 'INR'"
              >&#x20B9;</span
            >
            <!-- <p v-if="summaryDetails.planinterval === `YEAR`">{{ planAndDiscount.offerPrice / 100 }}</p> -->
						<p >{{ totalCost }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="coupon-container" v-if="isCouponEnabled && showCreditCard">
      <h4 class="coupon-query bold-text">{{ $t("have a coupon code?") }}</h4>
      <div class="coupon-input">
        <input type="text" :placeholder="couponCodePlaceholder" id="input" v-model="couponCode" />
        <Loading v-if="loader"></Loading>
        <span
          id="remove"
          :class="[localDisplayLang === 'ara' ? 'coupon-actions-ara' : 'coupon-actions']"
          v-else-if="showRemove"
          @click="removeCoupon"
          >{{ $t("Remove") }}</span
        >
        <span
          id="verify"
          :class="[localDisplayLang === 'ara' ? 'coupon-actions-ara' : 'coupon-actions']"
          v-else
          @click="verifyCoupon"
          >{{ $t("Apply") }}</span
        >
      </div>
      <div class="couponText" v-if="showRemove">
        <p>{{ applyCouponText }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import snackbar from "node-snackbar";
import Utility from "@/mixins/Utility.js";
import "../../../../node_modules/node-snackbar/dist/snackbar.css";
import { mapGetters } from "vuex";

import { showSnackBar } from '@/utilities.js';

export default {
  props: {
    orderDetails: {
      type: Object,
    },
    couponCodeProp: {
      type: String,
    },
  },
  data() {
    return {
      currentDate: null,
      planType: "Premium",
      validity: null,
      costPrice: "",
      tax: "0",
      spPrice: "0",
      totalCost: "",
      couponCode: null,
      summaryDetails: null,
      loader: false,
      showRemove: false,
      discountPrice: null,
      couponPrecheckObj: null,
      localDisplayLang: null,
      isCouponEnabled: false,
      applyCouponText: "",
      planAndDiscount : null,
      showCreditCard: true,
      planTag: null,
      currentUserCountry:null,
      subscriberDetails: null
    };
  },
  watch: {
    totalCost(val) {
      if (val == 0) {
        if (this.orderDetails.transactionPurpose !== "PURCHASE") {
          // console.log("this.orderDetails", this.orderDetails);
          let payload = {
            transactionpurpose: this.orderDetails.transactionPurpose,
            planid: this.couponPrecheckObj.planid,
            availabilityid: this.couponPrecheckObj.availabilityid,
            couponid: this.couponPrecheckObj.couponid,
          };
          // console.log("payload for create subscription", payload);
          eventBus.$emit("create-free-subscription", payload);
        } else {
          let payload = {
            objectid: this.orderDetails.content.objectid,
            availabilityid: this.couponPrecheckObj.availabilityid,
            priceclassid: this.couponPrecheckObj.priceclassid,
            couponid: this.couponPrecheckObj.couponid,
          };
          eventBus.$emit("create-free-purchase", payload);
        }
      }
    },
  },
  computed: {
    ...mapGetters(["appConfig", "subscriptionList"]),
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.summaryDetails = this.orderDetails.detail;
    console.log("summary details ======", this.summaryDetails);
    this.couponCodePlaceholder = this.$t("coupon code");
    this.currentDate = this.getCurrentDate();
    // this.getValidity();

    this.isCouponEnabled = this.appConfig.featureEnabled.hasCouponEnabled;
		//this.planAndDiscount = this.appConfig.planDiscount;
    this.planTag = this.appConfig.planTags[this.orderDetails.detail.planid]
      ? this.appConfig.planTags[this.orderDetails.detail.planid]
      : "";
    this.subscriberDetails = JSON.parse(localStorage.getItem("subscriberDetails")) ? JSON.parse(localStorage.getItem("subscriberDetails")) : null;
    let subscribedUserCountry = this.subscriberDetails ? this.subscriberDetails.data?.subscribercountry : null;
    this.currentUserCountry = localStorage.getItem("currentUserCountry")
        ? JSON.parse(localStorage.getItem("currentUserCountry"))
        : null;
    this.planAndDiscount = this.appConfig.planDiscount[subscribedUserCountry ? subscribedUserCountry : this.currentUserCountry.CountryCode] ? this.appConfig.planDiscount[subscribedUserCountry ? subscribedUserCountry : this.currentUserCountry.CountryCode] : this.appConfig.planDiscount["DEFAULT"];
    this.getValidity();
  },
  mounted() {
	this.showCreditCardMethod();
    let localCouponCode = localStorage.getItem("offerCoupon");

    if(localCouponCode) {
      localCouponCode = JSON.parse(localCouponCode);
    }

    if(this.subscriptionList && this.subscriptionList.length > 0) {
      eventBus.$emit("close-payment-popup");
      this.$router.push("/");
      showSnackBar("Already Subscribed");
    }

    if(localCouponCode && localCouponCode.planId === this.orderDetails.detail.planid) {
      this.couponCode = localCouponCode.coupon;

      if (this.couponCode) {
        this.verifyCoupon();
      }
    } else if (this.couponCodeProp) {
      this.couponCode = this.couponCodeProp;

      if (this.couponCode) {
        this.verifyCoupon();
      }
    }

    eventBus.$on("validate-coupon", (response) => {
      console.log("coupon response", response);
      this.showRemove = true;
      document.getElementById("input").onkeydown = function(event) {
        event.preventDefault();
      };

      if (response.reason) {
        this.loader = false;

        if (response.errorcode === 5095) {
          eventBus.$emit("close-plan-popup", true);
        }

        console.log("THIS IS RESPONSE ------- ", response);

        this.removeCoupon();

        localStorage.removeItem("offerCoupon");

        showSnackBar(this.$t(response.reason));

      } else {
        
        this.applyCouponText = this.getCouponText(this.appConfig.messagesList, response.nextbilling);
        this.couponPrecheckObj = response;
        this.costPrice = this.planTag ? this.planAndDiscount.actualPrice : (response.amount / 100).toFixed(2);
				this.discountPrice = (response.discount / 100).toFixed(2);
        //this.discountPrice = this.planTag ? this.planAndDiscount.discountedPrice : 0;
         this.totalCost = (response.finalamount / 100).toFixed(2);
        //this.totalCost = this.costPrice - this.discountPrice;

        if (this.totalCost !== 0) {
          this.loader = false;
          let payload = {
            totalFinalCost: parseInt(this.totalCost * 100),
            couponCode: response.couponid,
            isCoupon: true,
          };

          const analyticsData = {
            nextbilling: response.nextbilling,
            couponCode: payload.couponCode,
            discountPrice: this.planTag ? this.planAndDiscount.discountedPrice : this.discountPrice,
          };

          localStorage.setItem("payment_analytics_data", JSON.stringify(analyticsData));

          console.log("THE PRECHECK --- ", analyticsData);

          localStorage.removeItem("offerCoupon");

          this.$emit("totalAfterDiscount", payload);
        }
      }
    });
  },
  methods: {
	showCreditCardMethod() {
	if (localStorage.getItem("currentUserCountry")) {
		this.currentUserCountry = localStorage.getItem("currentUserCountry") ? JSON.parse(localStorage.getItem("currentUserCountry")) : null;
		let filterCountries = this.appConfig.countryFilter;
		console.log(filterCountries.includes(this.currentUserCountry.CountryCode));
			if(filterCountries.includes(this.currentUserCountry.CountryCode)){
			this.showCreditCard = false;
			}
		}
	},
    //coupon Validation API call
    verifyCoupon() {
      if (this.couponCode) {
        this.couponCode = this.couponCode.trim();
      }

      if (!this.couponCode) {

        showSnackBar(this.$t("Please Enter Coupon code"));

        return;
      } else {
        this.loader = true;
        // coupon validation for plans
        if (this.orderDetails.transactionPurpose !== "PURCHASE") {
          let payload = {
            transactionpurpose: this.orderDetails.transactionPurpose,
            planid: this.orderDetails.detail.planid,
            couponid: this.couponCode,
          };
          eventBus.$emit("plan-coupon-precheck", payload);
        }
        // coupon validation for purchases
        else {
          let payload = {
            objectid: this.orderDetails.content.objectid,
            availabilityid: this.orderDetails.detail.availabilityid,
            priceclassid: this.orderDetails.detail.priceclassid,
            couponid: this.couponCode,
          };
          eventBus.$emit("purchase-coupon-precheck", payload);
        }
      }
    },
    //remove coupon
    removeCoupon() {
      document.getElementById("input").onkeydown = function(event) {
        event.returnValue = true;
      };

      this.showRemove = false;
      this.applyCouponText = "";
      this.couponCode = null;
      this.costPrice = this.planTag ? this.planAndDiscount.actualPrice : parseInt(this.summaryDetails.amount) / 100;
      this.discountPrice = this.planTag ? this.planAndDiscount.discountedPrice :  0;
      this.totalCost = parseInt(this.summaryDetails.amount) / 100;

      let payload = { totalFinalCost: this.totalCost * 100, couponCode: this.couponCode, isCoupon: false };
      this.$emit("totalAfterDiscount", payload);
    },
    getCurrentDate() {
      let d = new Date();
      let months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return d.getDate() + " " + months[d.getMonth()] + " " + d.getFullYear();
    },
    getValidity() {
      this.validity =
        this.summaryDetails.planinterval === "MONTH"
          ? "30"
          : this.summaryDetails.planinterval === "YEAR"
          ? "365"
          : this.summaryDetails.planinterval === "HALFYEAR"
          ? "183"
          : this.summaryDetails.planinterval === "WEEK"
          ? "7"
          : "90";
      this.discountPrice = this.planTag ? this.planAndDiscount.discountedPrice :  0;
      this.costPrice = this.planTag ? this.planAndDiscount.actualPrice : parseInt(this.summaryDetails.amount) / 100;
      this.totalCost = parseInt(this.summaryDetails.amount) / 100;
    },
    getCouponText(messageList, date) {
      if (this.localDisplayLang == "ara") {
        return messageList.ar.couponApplyMessage + " " + `(${this.displayDateFormat(date)})`;
      } else {
        return messageList.en.couponApplyMessage + " " + `(${this.displayDateFormat(date)})`;
      }
    },
  },
  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },
  beforeDestroy() {
    eventBus.$off("validate-coupon");
  },
  mixins: [Utility],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
.order-summary {
  font-family: $font-regular;
  // width: 85%;
  width: 100%;
  .order-container {
    padding: 2rem;
  }
  .plan-title {
    margin-top: 2em;
  }
  .cost-details {
    margin: 2em 0em 1.5em;
    .price {
      display: flex;
      .currency {
        margin-right: 5px;
      }
      .currency_rtl {
        margin-right: 5px;
      }
    }
  }
  .rows {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .coupon-container {
    background-color: $clr-dark-gd3;
    padding: 1rem 2rem;
    .coupon-query {
      padding-bottom: 20px;
    }
    .coupon-input {
      position: relative;
      input {
        color: $clr-light-gd2;
        letter-spacing: 0.25em;
        border-radius: 2px;
      }
      .coupon-actions {
        position: absolute;
        top: 35%;
        right: 3%;
        font-weight: bold;
        font-size: 12px;
        cursor: pointer;
      }
      .coupon-actions-ara {
        position: absolute;
        top: 35%;
        left: 3%;
        font-weight: bold;
        font-size: 12px;
        cursor: pointer;
      }
      #verify {
        color: $btn-clr;
      }
      #remove {
        color: red;
      }
    }
    .couponText {
      color: #868688;
      text-align: justify;
      font-size: 12px;
    }
  }
  .total-cost {
    line-height: 2;
  }
  .bold-text {
    color: $clr-light-gd2;
  }
  .light-text {
    color: $clr-popup-para;
    padding: 5px 0;
  }
  .small-text-rtl {
    text-align: right;
    font-size: 0.9rem;
  }
  .small-text {
    font-size: 0.9em;
  }
}

@media only screen and (max-width: 576px) {
  .order-summary {
    width: 100%;
    .order-container {
      padding: 1.2rem;
      height: 270px;
      h2 {
        font-size: 18px;
      }
    }
    .plan-title {
      margin-top: 0.5em;
      h3 {
        font-size: 16px;
        // text-transform: lowercase;
      }
    }
    .cost-details {
      margin: 0.5em 0em 1.5em;
    }
    .coupon-container {
      padding: 1rem;
    }
  }
}
</style>
